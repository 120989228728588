export default {
  'slot1': "6546499882",
  'slot2': "8502262155",
  'slot3': "5876098819",
  'firebaseConfig': {
 apiKey: "AIzaSyD5dh6iuSz9jnBqSmsANIvV_5GhL2hWQz4",
  authDomain: "news15-network.firebaseapp.com",
  projectId: "news15-network",
  storageBucket: "news15-network.appspot.com",
  messagingSenderId: "963155964966",
  appId: "1:963155964966:web:dcd71efbba16841aae22e7",
  measurementId: "G-MFXT2E2LHV"
  }
}